@import url('https://fonts.googleapis.com/css?family=Open+Sans:regular,bold,italic&subset=latin,latin-ext');
@import url('./coloring.css');
@import url('./spacing.css');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

tbody .ant-table-cell {
  border-right: 1px solid #f0f0f0;
}

tbody .ant-table-cell-with-append {
  border-left: 1px solid #f0f0f0;
}

.ant-modal-header {
  border-bottom: 1px solid lightgrey;
  padding-bottom: 8px;
}

.lightMediumGray--text {
  color: #F5F5F5;
}

.lightMediumGray {
  background-color: #F5F5F5;
}

.hsw-form h5 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #061938 !important;
  margin-bottom: 16px;
  margin-top: 4px !important;
}

.hsw-form h4 {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff !important;
}

.hsw-label {
  position: absolute;
  top: -8px;
  left: 8px;
  font-size: 10px !important;
  line-height: 10px !important;
  background-color: white;
  padding-top: 2px;
  padding-bottom: 0;
  padding-right: 4px;
  padding-left: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.hsw-label-disabled {
  background-color: rgba(245, 245, 245);
}

.hsw-label-hover {
  color: #4096ff;
}

.hsw-label:before,
.hsw-label:after {
  content: '';
  display: block;
  position: absolute;
  background-color: transparent;
  pointer-events: none;
}

.hsw-label:before {
  top: 0;
  left: 0;
  right: 50%;
  bottom: 30%;
  border-top-left-radius: 4px;
  border-top: 1px solid;
  border-left: 1px solid;
}

.hsw-label:after {
  top: 0;
  left: 50%;
  right: 0;
  bottom: 30%;
  border-top-right-radius: 4px;
  border-top: 1px solid;
  border-right: 1px solid;
}

.hsw-label::after {
  border-top-color: #d9d9d9;
  border-right-color: #d9d9d9;
}

.hsw-label::before {
  border-top-color: #d9d9d9;
  border-left-color: #d9d9d9;
}

.hsw-label-hover::after {
  border-top-color: #4096ff;
  border-right-color: #4096ff;
}

.hsw-label-hover::before {
  border-top-color: #4096ff;
  border-left-color: #4096ff;
}

.hsw-label-danger::after {
  border-top-color: #F05379;
  border-right-color: #F05379;
}

.hsw-label-danger::before {
  border-top-color: #F05379;
  border-left-color: #F05379;
}

.pa-1 {
  padding: 4px;
}

.pa-2 {
  padding: 8px;
}

.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.px-1 {
  padding-right: 4px;
  padding-left: 4px;
}

.px-2 {
  padding-right: 8px;
  padding-left: 8px;
}

.ma-1 {
  margin: 4px;
}

.ma-2 {
  margin: 8px;
}

.my-1 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mx-1 {
  margin-right: 4px;
  margin-left: 4px;
}

.mx-2 {
  margin-right: 8px;
  margin-left: 8px;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-5 {
  margin-top: 20px;
}

.mt-6 {
  margin-top: 24px;
}

.mt-7 {
  margin-top: 28px;
}

.mt-8 {
  margin-top: 32px;
}

.mb-1-i {
  margin-bottom: 4px !important;
}

.mb-2-i {
  margin-bottom: 8px !important;
}

.mb-3-i {
  margin-bottom: 12px !important;
}

.mb-4-i {
  margin-bottom: 16px !important;
}

.mb-5-i {
  margin-bottom: 20px !important;
}

.mb-6-i {
  margin-bottom: 24px !important;
}

.mb-7-i {
  margin-bottom: 28px !important;
}

.mb-8-i {
  margin-bottom: 32px !important;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-5 {
  margin-bottom: 20px;
}

.mb-6 {
  margin-bottom: 24px;
}

.mb-7 {
  margin-bottom: 28px;
}

.mb-8 {
  margin-bottom: 32px;
}

.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 8px;
}

.ma-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.flex-start-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.row {
  display: flex;
  flex-direction: row;
}

.row {
  display: flex;
  flex-direction: column !important;
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse;
}

.flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-start-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.flex-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ant-table-cell {
  padding: 12px !important;
}

.loading-overlay {
  position: absolute;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-transcript-row {
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding-left: 8px;
  border: 1px solid #d9d9d9;
  padding: 4px;
  cursor: pointer;
}

.add-transcript-row p {
  margin-top: 0;
  margin-bottom: 0;
}

.nowrap {
  white-space: nowrap;
  overflow-x: hidden;
}

.none {
  display: none;
}

.form-section {
  outline: solid rgba(200, 200, 200, 0.7) 1px;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 8px;
  margin-bottom: 4px;
}

.issue {
  border: solid #0099FF 1px;
}

#toggle-menu:hover {
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.center-between {
  align-items: center;
  justify-content: space-between;
}

.webapp-row .ant-table-cell {
  border-right: none !important;
}

.pointer {
  cursor: pointer;
}

.radio-top .ant-radio {
  position: absolute;
  top: 40px;
}

.enabled {
  color: #000000 !important;
  background-color: darkgray !important;
}

.enabled {
  input {
    color: #000000 !important;
    background-color: darkgray !important;
  }
}

.danger--text_enabled {
  background-color: darkgray !important;
  color: #F05379 !important;
}

.danger--text_enabled {
  input {
    color: #F05379 !important;
    background-color: darkgray !important;
  }
}

.enabled-bg {
  background-color: darkgray !important;
}

.enabled-bg {
  input {
    background-color: darkgray !important;
  }
}

.notscript {
  background-color: #FFFBE6;
}

.notscript {
  input {
    background-color: #FFFBE6;
  }
}

.blue_rectangle {
  background-color: lightblue;
  color: lightblue;
  height: 12px;
  width: 24px;
}

.h360-title {
  font-size: 16px;
  color: #1677FF;
  font-weight: bold;
}

.lighterGray--text {
  color: #EAEAEA;
}

.lighterGray {
  background-color: #EAEAEA;
}

.flex-start-start {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.flex-start-between {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.flex-start-around {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.flex-end-end {
  display: flex;
  align-items: end;
  justify-content: end;
}

.flex-center-end {
  display: flex;
  align-items: center;
  justify-content: end;
}

.flex-end-center {
  display: flex;
  align-items: center;
  justify-content: end;
}

.pl-n4 {
  padding-left: -16px;
}

.ml-n4 {
  margin-left: -16px;
}

.pl-0 {
  padding-left: 0;
}

.pl-1 {
  padding-left: 4px;
}

.pl-2 {
  padding-left: 8px;
}

.pl-3 {
  padding-left: 12px;
}

.pl-4 {
  padding-left: 16px;
}

.pl-5 {
  padding-left: 20px;
}

.pl-6 {
  padding-left: 24px;
}

.pl-7 {
  padding-left: 28px;
}

.pl-8 {
  padding-left: 32px;
}

.mbi-0 {
  margin-bottom: 0 !important;
}

.mti-0 {
  margin-top: 0 !important;
}

.mti-1 {
  margin-top: 4px !important;
}

.myi-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.h2 label {
  font-size: 12px !important;
}

.border {
  border: lightgray 1px solid;
}

.card {
  border: lightgray 1px solid;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 4px;
}

.pa-0 {
  padding: 0;
}

.bordered {
  border: gray;
}

.orderer_name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
  font-size: 14px;
  font-weight: 600;
}

.analyzer_card_title {
  font-size: 18px;
  font-weight: 600;
  margin-top: 4px;
}

.no_body & .ant-card-body {
  display: none;
}

.pointer {
  cursor: pointer;
}

/* .year-input .ant-select-selector {
  background-color: #F9FAFF;
}

.year-input .ant-select-selector .ant-select-selection-item {
  font-size: 16px;
  font-weight: bold;
  color: #1677FF;
} */

.danger_year-input {
  color: red !important;
}

.analyzer_card_title {
  font-size: 18px;
  font-weight: 600;
}


.no-arrow-popover .ant-popover-arrow {
  display: none;
}

.no-title-border .ant-card-head {
  border-bottom: none;
}

.analyzer_start {
  background-color: #E6F4FF;
}

.mb-0 .ant-tabs-nav {
  margin-bottom: 0;
}

.custom-bullet {
  display: flex;
  align-items: flex-start;
  width: fit-content;
}

.custom-bullet::before {
  content: '';
  border-radius: 4px;
  margin-right: 8px;
  margin-top: 4px;
}

.custom-bullet.purple::before {
  min-width: 18px;
  height: 14px;
  border: 1px solid #d9d9d9;
  background-color: #F9F0FF;
}

.custom-bullet.yellow::before {
  min-width: 18px;
  height: 14px;
  border: 1px solid #d9d9d9;
  background-color: #FFFBE6;
}

.custom-bullet.edited-bullet::before {
  min-width: 14px;
  height: 14px;
  border: 1px solid #d9d9d9;
  border-radius: 14px;
  background-color: #FF4D4F;
}

.col {
  flex-direction: column;
}

.colors-list {
  list-style-type: none;
  padding: 0;
}

.colors-list li {
  margin-bottom: 8px;
}

.arow-0 {
  padding-left: 0;
}

.arow-1 {
  padding-left: 4em;
}

.arow-2 {
  padding-left: 8em;
}

.arow-3 {
  padding-left: 12em;
}

.arow-4 {
  padding-left: 16em;
}

.arow-5 {
  padding-left: 20em;
}

.arow-6 {
  padding-left: 24em;
}

.arow-7 {
  padding-left: 28em;
}

.arow-8 {
  padding-left: 32em;
}

.drop-left {
  margin-left: -20px;
}

.has_override {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: red;
  content: '';
  position: absolute;
  top: -3px;
  left: -2px;
  z-index: 400;
}

.botbor {
  border-bottom: 1px solid #F5F5F5;
}

.analyzer_row {
  border-radius: 4px;
}

.gray_bg {
  background-color: #D6D6D6 !important;
}

.year_btn_box {
  border: 1px #d9d9d9 solid;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.year_btn_box.btn_box_disabled {
  background-color: #F5F5F5;
}

.w80 {
  width: 80%;
}

.w100 {
  width: 100%;
}

.h100 {
  height: 100vh;
}

.hfc {
  height: fit-content;
  min-height: 100vh;
}

h5.ant-typography {
  margin-top: 0;
}
h4.ant-typography {
  margin-top: 0;
}
.center {
  margin: auto;
}
