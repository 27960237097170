.white--text {
    color: #FFFFFF;
}
.white {
    background-color: #FFFFFF !important;
}

.black--text {
    color: #000000;
}
.black {
    background-color: #000000;
}

.dark--text {
    color: #16161d;
}
.dark {
    background-color: #16161d;
}

.background--text {
    color: #F0F2F5;
}
.background {
    background-color: #F0F2F5;
}

.danger--text {
    color: #F05379;
}
.danger {
    background-color: #F05379;
}
.danger--border {
    border-color: #F05379;
}

.darkBlue--text {
    color: #055FFC;
}
.darkBlue {
    background-color: #055FFC;
}

.mediumBlue--text {
    color: #3366CC;
}
.mediumBlue {
    background-color: #3366CC;
}

.lightBlue--text {
    color: #0099FF;
}
.lightBlue {
    background-color: #0099FF;
}

.success--text {
    color: #4C9A2A;
}
.success {
    background-color: #4C9A2A;
}

.darkGreen--text {
    color: #009280;
}
.darkGreen {
    background-color: #009280;
}

.green--text {
    color: #68c0a9;
}
.green {
    background-color: #68c0a9;
}

.lightGreen--text {
    color: #F2F9F8;
}
.lightGreen {
    background-color: #F2F9F8;
}

.lightBackground--text {
    color: #f8fafc
}
.lightBackground {
    background-color: #f8fafc
}

.lightGray--text {
    color: #D3D3D3;
}
.lightGray {
    background-color: #D3D3D3;
}

.input-field input {
    background-color: #F0F2F5;
}

.required .ant-select-selector::before {
    color: #F05379;
    content: "*";
    width: 10px;
}

.dangerButton {
    border: solid 1px #F05379;
    background-color: transparent;
    color: #F05379;
}

.dangerButton:hover {
    background-color: #F05379 !important;
    color: white;
}

/* :where(.css-dev-only-do-not-override-htwhyh).ant-btn-primary:not(:disabled):hover */

.activeText {
    font-weight: 600;
    color: #0099FF;
    text-decoration: underline;
}

.selectText:hover {
    cursor: pointer;
    color: #00BBFF;
}

.active-bg {
    background-color: #055FFC;
}

.active-bg h4 {
    color: #ffffff;
}

.active-bg p {
    color: #ffffff;
}
